/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #86ddff;
  --countdown-background-color: #0079a8;
  --main-text-color:#ebf2ff;
  --title-text-color:#8077ff;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("img/background.png");
  background-size: cover;
  font-family: 'Patrick Hand', cursive;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



